<template>
  <div class="full-width full-height">
    <div class="ht-style-t">
      <div v-hasPermiss="'10921110'" class="full-height">
        <title-com
          text="联网状况"
          :showScreen="true"
          @goView="viewNet"
          class="m-b-sm"
        ></title-com>
        <left-menu
          :list="menuList"
          class="m-b-md"
          @showCom="showComHandle"
        ></left-menu>
        <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht">
          <top-static
            :list="networkStatistic"
            :column="curColumn"
            @sortList="handleSortDataList"
          ></top-static>
        </vue-scroll>
      </div>
    </div>

    <div class="ht-style-b">
      <div v-hasPermiss="'10921111'" class="full-height">
        <title-com
          text="截图"
          :showScreen="true"
          class="m-b-sm"
          @goView="viewScreen"
        ></title-com>

        <div class="on-con relative ht-style-b-video">
          <span class="absolute cor t-lf"></span>
          <span class="absolute cor t-rf"></span>
          <span class="absolute cor b-lf"></span>
          <span class="absolute cor b-rf"></span>
          <div class="block btn" @click="viewScreen">
            <div class="screenshot" />
            <div class="content">
              <div class="title">截图</div>
              <div class="data">{{ screenShotCount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import LeftMenu from '@/components/LeftMenu';
import TopStatic from './TopStatic';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'TopLeft',
  data() {
    return {
      menuList: [
        {
          id: 0,
          name: '接入率',
        },
        {
          id: 1,
          name: '在线率',
        },
        {
          id: 3,
          name: '正常率',
        },
        {
          id: 4,
          name: '故障率',
        },
        /*  {
          id: 4,
          name: '共享统计',
        }, */
      ],
      currentMenu: 0,
      curColumn: [],
      column0: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'totalAccessCount',
          name: '已接入量',
          sort: false,
        },
        {
          key: 'accessedCount',
          name: '应接入量',
          sort: false,
        },
        {
          key: 'accessedRate',
          name: '接入率',
          sort: true,
        },
      ],
      column1: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'onlineCount',
          name: '在线数',
          sort: false,
        },

        {
          key: 'onlineRate',
          name: '在线率',
          sort: true,
        },
      ],
      column3: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'normalCount',
          name: '正常数',
          sort: false,
        },

        {
          key: 'normalRate',
          name: '正常率',
          sort: true,
        },
      ],
      column4: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'abnormalCount',
          name: '故障数',
          sort: false,
        },

        {
          key: 'abnormalRate',
          name: '故障率',
          sort: true,
        },
      ],
      columnDepart0: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'totalAccessCount',
          name: '已接入量',
          sort: false,
        },
        {
          key: 'accessedCount',
          name: '应接入量',
          sort: false,
        },
        {
          key: 'accessedRate',
          name: '接入率',
          sort: false,
        },
      ],
      columnDepart1: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'onlineCount',
          name: '在线数',
          sort: false,
        },

        {
          key: 'onlineRate',
          name: '在线率',
          sort: false,
        },
      ],
      columnDepart3: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'normalCount',
          name: '正常数',
          sort: false,
        },

        {
          key: 'normalRate',
          name: '正常率',
          sort: false,
        },
      ],
      columnDepart4: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'abnormalCount',
          name: '故障数',
          sort: false,
        },

        {
          key: 'abnormalRate',
          name: '故障率',
          sort: false,
        },
      ],
      // dataList: [],
      sortType: 1, //0默认取消
    };
  },
  components: {
    TitleCom,
    LeftMenu,
    TopStatic,
  },
  computed: {
    ...mapState({
      networkStatistic: state => state.resource.networkStatistic,
      screenShotData: state => state.resource.screenShotData,
    }),
    screenShotCount() {
      const countStr = (this.screenShotData?.['截图张数'] || 0) + '';
      return countStr.replace(/\d+/, num =>
        num.replace(/\d(?=(\d{3})+$)/g, $1 => $1 + ',')
      );
    },
    screenShotCamera() {
      return this.screenShotData?.['截图摄像机'] || 0;
    },
  },
  watch: {},
  created() {
    if (this.$g.isDepart) {
      this.menuList = [
        {
          id: 0,
          name: '接入率',
        },
        {
          id: 1,
          name: '在线率',
        },
        {
          id: 3,
          name: '正常率',
        },
        {
          id: 4,
          name: '故障率',
        },
      ];
      this.currentMenu = 0;
    } else {
      this.menuList = [
        {
          id: 1,
          name: '在线率',
        },
        {
          id: 3,
          name: '正常率',
        },
      ];
      this.currentMenu = 1;
    }
  },
  mounted() {
    // this.curColumn = this[`column${this.currentMenu}`];
    if (this.$g.isDepart) {
      this.curColumn = this[`columnDepart${this.currentMenu}`];
    } else {
      this.curColumn = this[`column${this.currentMenu}`];
    }
    this._getDataList();
    this.getScreenShotCount();
  },
  methods: {
    ...mapActions(['getNetworkStatistics', 'getScreenShotCount']),
    _getDataList() {
      let data = this.$g.isDepart
        ? {}
        : {
            currPage: 0,
            pageSize: 0,
            sortField: this.currentMenu,
            sortType: this.sortType,
          };
      this.getNetworkStatistics(data);
    },
    handleSortDataList(flag) {
      this.sortType = flag ? 1 : 0;
      this._getDataList();
    },
    viewNet() {
      if (this.$g.isDepart) {
        this.$router.push({ path: '/resource/departnet-condition' });
      } else if (this.$g.treeDefinate) {
        this.$router.push({ path: '/resource/yn-condition' });
      } else {
        this.$router.push({ path: '/resource/net-condition' });
      }
    },
    viewScreen() {
      this.$router.push({ path: '/resource/screen-and-video' });
    },
    showComHandle(item) {
      this.currentMenu = item.id;
      if (this.$g.isDepart) {
        this.curColumn = this[`columnDepart${this.currentMenu}`];
      } else {
        this.curColumn = this[`column${this.currentMenu}`];
      }

      this.sortType = 1;
      this._getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.ht-style-b {
  .ht-style-b-video {
    padding: 0.8rem 0.5rem;

    .block {
      align-items: center;
      background: 100% 100% / cover no-repeat #1b305566;
      background-image: -webkit-image-set(
        url(~@assets/resource/screenshot_bg.png) 1x,
        url(~@assets/resource/screenshot_bg@2x.png) 2x
      );
      background-image: image-set(
        url(~@assets/resource/screenshot_bg.png) 1x,
        url(~@assets/resource/screenshot_bg@2x.png) 2x
      );
      display: flex;
      height: 100%;

      .screenshot {
        background: 100% 100% / cover no-repeat;
        background-image: -webkit-image-set(
          url(~@assets/resource/screenshot_icon.png) 1x,
          url(~@assets/resource/screenshot_icon@2x.png) 2x
        );
        background-image: image-set(
          url(~@assets/resource/screenshot_icon.png) 1x,
          url(~@assets/resource/screenshot_icon@2x.png) 2x
        );
        height: 67px;
        margin: 0 4% 0 8%;
        width: 56px;
      }

      .content {
        .title {
          color: #55f0fb;
        }

        .data {
          color: @textColor;
          font-size: 1.1rem;
        }
      }
    }
  }
}
</style>
<style scoped>
.ht-style-t {
  height: calc(100% - 200px);
  margin-bottom: 10px;
}
.ht-style-b {
  height: 190px;
}
.scroll-ht {
  height: calc(100% - 86px) !important;
}
.ht-style-b-video {
  height: calc(100% - 40px) !important;
}
.p-md-depart {
  padding: 20px 20px 5px;
}
</style>
