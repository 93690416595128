<template>
  <!--千方地图-->
  <div class="qf-map_wrap">
    <!--地图容器-->
    <div class="qf-map-box" id="mapCon"></div>

    <div class="gd-map" id="gdMap"></div>
  </div>
</template>

<script>
// import { wgs84_to_gcj02, gcj02_to_wgs84 } from '@/tools/wgs84_to_gcj02.js';
import * as olProj from 'ol/proj';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Map from 'ol/Map';
import * as format from 'ol/format';
import * as olLoadingstrategy from 'ol/loadingstrategy';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import LayerGroup from 'ol/layer/Group';
// import Overlay from 'ol/Overlay';
// import XYZ from 'ol/source/XYZ';

const AMap = window.AMap;
const AMapUI = window.AMapUI;

export default {
  components: {},
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    let geoserverUrl = 'https://gis-t.chinahighway.org.cn:8001';
    let trafficUrl = 'http://39.96.167.193:8003';
    return {
      cameraMarkVisable: false,
      fsssWfsUrl: 'https://gis-t.chinahighway.org.cn:8001',
      geoserverUrl,
      geowebcacheUrl: 'https://gis-t.chinahighway.org.cn:8001',
      trafficUrl,
      opacity_color: 0,
      layer: [], // map中的图层数组
      layerName: [], // 图层名称数组
      layerVisibility: [], // 图层可见属性数组
      lwzxbkMapLayer: null,
      lwzxrouteMapLayer: null,
      lwzxblueMapLayer: null,
      lwzxzhujiMapLayer: null,
      trffic_layer: null,
      style_line: null,
      projection: olProj.get('EPSG:3857'),
      vectorSource: null,
      vectorLayer: null,
      view: null,
      mapInstance: null,
      wmsVectorLayer_china: null, // 国界面图层
      wmsVectorLayer_71118: null, // 71118图层
      wmsVectorLayer_road: null, // 高速

      camVectorLayer: [],
      currentPlayCamera: {},
      converVectorLayer: null,
      actCamvectorLayer: null,
      converLayer: null,

      gdMap: null, // 高德地图
      districtExplorer: null, // 高德地图区域
      initZoom: 3.5, // 默认加载层级
      initCenter: olProj.fromLonLat([107.408718, 34.922369]), // 默认加载中心点
      getCamPostBody: {}, // 获取摄像机列表请求参数
      actProvinceId: 100000,
      // 地图点击在线的图标
      mark_b: {
        '0': require('@/assets/traffic_map/offline.png'),
        '1': require('@/assets/traffic_map/online.png'),
        '2': require('@/assets/traffic_map/error.png'),
      },
      // mark_b: require('@/assets/traffic_map/icon//back/'),
      beforeCurrentMarker: null,
    };
  },
  watch: {
    data() {
      this.addMarker();
    },
  },
  methods: {
    /**
     * 地图加载
     */
    mapLoad() {
      var _this = this;
      // 地图图层初始化配置
      this.view = new View({
        projection: _this.projection, // 坐标系如果不设置，默认为墨卡托
        center: _this.initCenter, // 设置地图中心点
        zoom: _this.initZoom, // 初始化显示层级
        minZoom: 3, // 最小显示层级
        maxZoom: 16, // 最大显示层级
        // constrainResolution: true, // 因为存在非整数的缩放级别，所以设置该参数为true来让每次缩放结束
        // 后自动缩放到距离最近的一个整数级别，这个必须要设置，当缩放在非整数级别时地图会糊
      });

      this.mapInstance = new Map({
        target: 'mapCon', // 容器id
        layers: [
          // _this.lwzxbkMapLayer,背景白色图层
          _this.lwzxzhujiMapLayer,
          _this.lwzxblueMapLayer,
          _this.wmsVectorLayer_china,
          // _this.wmsVectorLayer_71118,
        ], // 默认加载图层
        view: _this.view,
      });
      this.addVideoLayer();
      this.mapInstance.on('moveend', () => {
        this.mapChanged();
      });
    },

    mapChanged() {
      let that = this;
      //0.5秒防抖
      clearTimeout(window.setMapTimeout);
      window.setMapTimeout = setTimeout(function() {
        if (that.infoWindow) {
          that.infoWindow.close();
        }
        that.$emit('amapChanged');
      }, 500);
    },
    /**
     * 地图默认图层样式加载
     */
    mapConfigLoad() {
      // 地图底图加载
      this.lwzxbkMapLayer = new TileLayer({
        //extent: [2423601.4157865737,-2324350.537143386,19812052.859356128,9945726.086343195],
        name: '背景',
        source: new TileWMS({
          url: this.geowebcacheUrl + '/geowebcache/service/wms',
          params: {
            VERSION: '1.1.1',
            LAYERS: 'lwzx_bk',
            FORMAT: 'image/png',
            SRS: 'EPSG:3857',
          },
          tileGrid: new TileGrid({
            //resolution和conf.xml中保持一致
            resolutions: [
              78271.517018034036,
              39135.758509017018,
              19567.879254508509,
              9783.9394949623238,
              4891.9698797730935,
              2445.9849398865467,
              1222.992337651342,
              611.49616882567102,
              305.74808441283551,
              152.87417449834899,
              76.436954957243259,
              38.218609770552874,
              19.109304885276437,
              9.5546524426382184,
              4.7773262213191092,
              2.3886631106595546,
              1.1943315553297773,
              0.59716577766488865,
            ],
            tileSize: [256, 256],
            origin: [-22041258.62706707, 33265069.154240012],
          }),
        }),
      });

      // 路线标记加载

      // 蓝背景加载
      this.lwzxblueMapLayer = new TileLayer({
        //extent: [2423601.4157865737,-2324350.537143386,19812052.859356128,9945726.086343195],
        name: '蓝背景',
        source: new TileWMS({
          url: this.geowebcacheUrl + '/geowebcache/service/wms',
          params: {
            VERSION: '1.1.1',
            LAYERS: 'lwzx_blue',
            FORMAT: 'image/png',
            SRS: 'EPSG:3857',
          },
          tileGrid: new TileGrid({
            //resolution和conf.xml中保持一致
            resolutions: [
              78271.517018034036,
              39135.758509017018,
              19567.879254508509,
              9783.9394949623238,
              4891.9698797730935,
              2445.9849398865467,
              1222.992337651342,
              611.49616882567102,
              305.74808441283551,
              152.87417449834899,
              76.436954957243259,
              38.218609770552874,
              19.109304885276437,
              9.5546524426382184,
              4.7773262213191092,
              2.3886631106595546,
              1.1943315553297773,
              0.59716577766488865,
            ],
            tileSize: [256, 256],
            origin: [-22041258.62706707, 33265069.154240012],
          }),
        }),
      });

      // 高速公路标记加载
      this.lwzxzhujiMapLayer = new TileLayer({
        //extent: [2423601.4157865737,-2324350.537143386,19812052.859356128,9945726.086343195],
        name: '标记',
        source: new TileWMS({
          url: this.geowebcacheUrl + '/geowebcache/service/wms',
          params: {
            VERSION: '1.1.1',
            LAYERS: 'lwzx_zhuji',
            FORMAT: 'image/png',
            SRS: 'EPSG:3857',
          },
          tileGrid: new TileGrid({
            //resolution和conf.xml中保持一致
            resolutions: [
              78271.517018034036,
              39135.758509017018,
              19567.879254508509,
              9783.9394949623238,
              4891.9698797730935,
              2445.9849398865467,
              1222.992337651342,
              611.49616882567102,
              305.74808441283551,
              152.87417449834899,
              76.436954957243259,
              38.218609770552874,
              19.109304885276437,
              9.5546524426382184,
              4.7773262213191092,
              2.3886631106595546,
              1.1943315553297773,
              0.59716577766488865,
            ],
            tileSize: [256, 256],
            origin: [-22041258.62706707, 33265069.154240012],
          }),
        }),
      });

      this.wmsVectorLayer_china = new TileLayer({
        name: '国界面',
        source: new TileWMS({
          url:
            this.geoserverUrl +
            '/geoserver/sf/wms?service=WMS&version=1.1.0&request=GetMap&layers=sf:china_borderline&width=512&height=399&srs=EPSG:3857&format=application/openlayers',
          //projection: 'EPSG:4326',
          params: {
            LAYERS: 'sf:china_borderline',
            VERSION: '1.0.0',
          },
          wrapX: false,
        }),
        zIndex: 10,
      });
    },
    addVideoLayer() {
      let wmsVectorLayer_road = new TileLayer({
        name: '视频',

        source: new TileWMS({
          url: 'https://gis-t.chinahighway.org.cn:8001/geoserver/sf/wms',
          //projection: 'EPSG:4326',

          params: {
            LAYERS: 'sf:toll_bd09_line_point',
            VERSION: '1.0.0',
            TILED: true,
          },
          serverType: 'geoserver',
          wrapX: false,
        }),
      });
      this.mapInstance.addLayer(wmsVectorLayer_road);
    },
    /**
     * 添加遮罩
     */
    addConverLayer(provinceId, camInfo = {}, flag = false) {
      var _this = this;

      if (provinceId === '') {
        this.cameraLoad();
        return;
      }

      if (flag) {
        var level = 'province';

        if ((provinceId + '').slice(2, 6) === '0000') {
          level = 'province';
        } else if ((provinceId + '').slice(4, 6) === '00') {
          level = 'city';
        }
        _this.$emit('amapClick', [provinceId, flag.value, level]);
      }

      this.getCamPostBody = camInfo;

      this.actProvinceId = provinceId;

      // 选中全国 地图初始化
      if (provinceId === 100000) {
        this.restoreMap();

        //全国蒙版
        var vectorSourceChina = new VectorSource({
          format: new format.GeoJSON(),
          loader: function(extent, resolution, projection) {
            var proj = projection.getCode();
            var url =
              _this.geoserverUrl +
              '/geoserver/sf/wfs?service=WFS&' +
              'version=1.0.0&request=GetFeature&typename=sf:china_polygon&' +
              'outputFormat=application/json&&srsname=' +
              proj;
            //'&' +
            //'bbox=' +
            //extent.join(',') ;

            var xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.setRequestHeader('Content-Type', 'application/json');
            var onError = function() {
              vectorSourceChina.removeLoadedExtent(extent);
            };
            xhr.onerror = onError;
            xhr.onload = function() {
              if (xhr.status == 200) {
                //把link更换一下
                vectorSourceChina.addFeatures(
                  vectorSourceChina.getFormat().readFeatures(xhr.responseText)
                );
              } else {
                onError();
              }
            };
            xhr.send();
          },
          strategy: olLoadingstrategy.bbox,
        });

        this.converLayer = new VectorLayer({
          name: 'chinamb',
          id: 'chinamb',
          source: vectorSourceChina,
          // eslint-disable-next-line no-unused-vars
          style: function(feature) {
            var style_line = new Style({
              fill: new Fill({
                color: 'rgba(17,100,237, 0.3)',
              }),
              stroke: new Stroke({
                color: '#08142b',
                width: 1.5,
              }),
            });
            return style_line;
          },
        });

        this.mapInstance.addLayer(this.converLayer);
        return;
      }

      // 地图位置图层切换
      this.getDisplayField(provinceId, function(error, areaNode) {
        var info = areaNode.getBounds();

        var x = '',
          y = '';

        // 获取显示中心点
        x = (info.xc.Q + info.oc.Q) / 2;
        y = (info.xc.R + info.oc.R) / 2;

        // 设置显示中心点/图层层级
        _this.mapInstance.getView().setZoom(areaNode._data.idealZoom);
        _this.mapInstance.getView().setCenter(olProj.fromLonLat([y, x]));
      });

      if (this.converLayer) {
        this.mapInstance.removeLayer(this.converLayer);
      }

      var PropertyName = '';
      var Literal = '';

      if ((provinceId + '').slice(2, 6) === '0000') {
        PropertyName = 'adminsheng';
        Literal = (provinceId + '').slice(0, 2);
      } else if ((provinceId + '').slice(4, 6) === '00') {
        PropertyName = 'adminshi';
        Literal = (provinceId + '').slice(0, 4);
      } else {
        PropertyName = 'adminxian';
        Literal = provinceId + '';
      }

      //全国蒙版
      // eslint-disable-next-line no-redeclare
      var vectorSourceChina = new VectorSource({
        format: new format.GeoJSON(),
        loader: function(extent, resolution, projection) {
          var proj = projection.getCode();
          var url =
            _this.geoserverUrl +
            '/geoserver/sf/wfs?service=WFS&' +
            'version=1.0.0&request=GetFeature&typename=sf:adm_borderline&' +
            `outputFormat=application/json&FILTER=<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">  <PropertyIsEqualTo><PropertyName>${PropertyName}</PropertyName><Literal>${Literal}</Literal></PropertyIsEqualTo> </Filter>&srsname=` +
            proj;
          //'&' +
          //'bbox=' +
          //extent.join(',') ;

          var xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.setRequestHeader('Content-Type', 'application/json');
          var onError = function() {
            vectorSourceChina.removeLoadedExtent(extent);
          };
          xhr.onerror = onError;
          xhr.onload = function() {
            if (xhr.status == 200) {
              //把link更换一下
              vectorSourceChina.addFeatures(
                vectorSourceChina.getFormat().readFeatures(xhr.responseText)
              );
            } else {
              onError();
            }
          };
          xhr.send();
        },
        strategy: olLoadingstrategy.bbox,
      });

      this.converLayer = new VectorLayer({
        name: 'chinamb',
        id: 'chinamb',
        source: vectorSourceChina,
        // eslint-disable-next-line no-unused-vars
        style: function(feature) {
          var style_line = new Style({
            fill: new Fill({
              color: 'rgba(17,100,237, 0.3)',
            }),
            stroke: new Stroke({
              color: '#08142b',
              width: 1.5,
            }),
          });
          return style_line;
        },
      });

      this.mapInstance.addLayer(this.converLayer);
    },
    addMarker() {
      if (this.beforeCurrentMarker) {
        this.mapInstance.removeLayer(this.beforeCurrentMarker);
      }

      let markList = this.data.map((item) => {
        let marker = this.addMarker2(
          item.longitude,
          item.latitude,
          item.onlineStatus
        );
        return marker;
      });
      let currentMarkers = new LayerGroup({
        layers: markList,
      });
      // console.error(currentMarkers);
      this.beforeCurrentMarker = currentMarkers;
      this.mapInstance.addLayer(currentMarkers);
    },
    addMarker2(lng, lat, sta) {
      let that = this;
      let rome = new Feature({
        geometry: new Point(olProj.fromLonLat([lng, lat])),
      });
      let imgUrl = null;
      switch (sta) {
        case '0':
          imgUrl = that.mark_b['0'];
          break;
        case '1':
          imgUrl = that.mark_b['1'];
          break;
        case '2':
          imgUrl = that.mark_b['2'];
          break;
        default:
          imgUrl = that.mark_b['1'];
      }
      rome.setStyle(
        new Style({
          image: new Icon({
            src: imgUrl,
          }),
          zIndex: 401,
        })
      );
      let vectorSource_point = new VectorSource({
        features: [rome],
      });
      let vectorLayer_point = new VectorLayer({
        name: '自定义打点图层',
        source: vectorSource_point,
        zIndex: 402,
      });
      return vectorLayer_point;
      // that.mapInstance.addLayer(vectorLayer_point);
    },

    /**
     * 获取区域code
     * @param  coodinate  区域经纬度 墨卡托坐标
     * @return Object     省份编码与名称
     */
    getAreaCode(coodinate) {
      let _this = this;
      let wgS84 = _this.mercatorTolonlat({ x: coodinate[0], y: coodinate[1] });
      let gcj02 = _this.$gcoord.transform(
        [wgS84.x, wgS84.y],
        _this.$gcoord.WGS84,
        _this.$gcoord.GCJ02
      );

      return _this.$axios.get(
        _this.geoserverUrl +
          `/API/getInverseGeocoding.do?lat=${gcj02[1]}&lng=${gcj02[0]}`
      );
    },

    /**
     * web墨卡托(3857)转经纬度坐标(4326)
     * @param mercator
     * @returns {{x: number, y: number}}
     */
    mercatorTolonlat(mercator) {
      var lonlat = { x: 0, y: 0 };
      var x = (mercator.x / 20037508.34) * 180;
      var y = (mercator.y / 20037508.34) * 180;
      y =
        (180 / Math.PI) *
        (2 * Math.atan(Math.exp((y * Math.PI) / 180)) - Math.PI / 2);
      lonlat.x = x;
      lonlat.y = y;
      return lonlat;
    },

    /**
     * 经纬度坐标(4326)转web墨卡托(3857)
     * @param lonlat
     * @returns {{x: number, y: number}}
     */
    lonLat2Mercator(lonlat) {
      var mercator = {
        x: 0,
        y: 0,
      };
      var earthRad = 6378137.0;
      mercator.x = ((lonlat.lng * Math.PI) / 180) * earthRad;
      var a = (lonlat.lat * Math.PI) / 180;
      mercator.y =
        (earthRad / 2) * Math.log((1.0 + Math.sin(a)) / (1.0 - Math.sin(a)));
      return mercator;
    },

    /**
     * 高德地图加载
     */
    gdMapLoad() {
      var _this = this;

      this.gdMap = new AMap.Map('gdMap', {
        cursor: 'default',
        resizeEnable: true,
        animateEnable: true,
        disableSocket: true,
        autoSetFitView: false,
        //viewMode: '3D',
        // showLabel: false,//不显示地图文字标记
        pitch: 0,
        zoom: 5,
        //mapStyle: 'amap://styles/1ca5820aeb977f88e1b07925d4ff1d4c',
        mapStyle: 'amap://styles/b7f705be4f2be54091ca786949404f82',
      });

      AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function(
        DistrictExplorer
      ) {
        //创建一个实例
        let distExp = new DistrictExplorer({
          // eventSupport: true, //打开事件支持
          map: _this.gdMap,
          preload: [100000],
          zIndex: 200,
        });

        _this.districtExplorer = distExp;
      });
    },

    /**
     * 获取地图显示范围
     */
    getDisplayField(adcode, callback) {
      var _this = this;

      this.districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
        //console.info('areaNode  ',adcode,areaNode);
        _this.districtExplorer.clearFeaturePolygons();
        if (error) {
          if (callback) {
            callback(error);
          }
          //console.error('loadAreaNode error ', error);
          return;
        }

        if (callback) {
          callback(null, areaNode);
        }
      });
    },

    /**
     * 恢复地图初始状态
     */
    restoreMap() {
      if (this.converLayer) {
        this.mapInstance.removeLayer(this.converLayer);
      }

      // 设置显示中心点/图层层级
      this.mapInstance.getView().setZoom(this.initZoom);
      this.mapInstance.getView().setCenter(this.initCenter);
    },
    /**
     * 销毁地图
     */
    destroyMap() {
      this.mapInstance.dispose();
    },
  },
  destroyed() {
    console.log('销毁地图3');
    this.destroyMap();
  },
  beforeMount() {},
  mounted() {
    this.$nextTick(() => {
      this.mapConfigLoad();
      this.mapLoad();
      this.addConverLayer(100000);
      // this.cameraLoad();
      this.gdMapLoad();
    });
  },
};
</script>

<style lang="less">
.qf-map_wrap {
  height: 100%;
  width: 100%;
  // background-image: url('../assets/images/traffic_map/map_bg.png');
  background-repeat: repeat;
  background-size: auto 120%;
  background-position: center;
  position: relative;

  > .qf-map-box {
    height: 100%;
    width: 100%;
  }
  .ol-zoom.ol-unselectable.ol-control,
  .ol-rotate.ol-unselectable.ol-control {
    display: none;
  }
}
</style>
