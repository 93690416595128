<template>
  <!--  full-width full-height -->
  <div :id="el" class="char-bar"></div>
</template>

<script>
import echarts from '@/tools/echarts';
export default {
  name: 'ChartBarCommon',
  props: {
    el: {
      type: String,
    },
    data: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    data: {
      handler() {
        this.$nextTick(() => {
          this.renderCharBar();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.renderCharBar();
    });
  },
  methods: {
    renderCharBar() {
      let _this = this;
      let obj = document.getElementById(this.el); //"abnormal-chart"
      if (!obj) {
        return false;
      }
      // console.error('------------', this.data);
      let dataArr = this.data.data.map((item) => {
        return {
          value: this.el === 'chart1' ? item.value : item.total,
          name: item.name,
          /* itemStyle: {
            color: '#3a689f',
          }, */
        };
      });
      //console.log("pieChartData=>", pieChartData, pieChartDataTotal);
      let myChart = echarts.init(obj);
      //var that = this;
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: true,
          trigger: 'item',
          // formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          color: '#86B3E9',
          show: false,
          orient: 'vertical',
          right: '0',
          top: '30%',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'rgba(134, 179, 233, 1)',
            rich: {
              a: {
                width: 40,
                fontSize: 12,
              },
              b: {
                color: '#86B3E9',
                fontSize: 12,
                width: 40,
              },
              c: {
                fontSize: 12,
                width: 40,
                color: '#fff',
              },
            },
          },
        },
        title: {
          text: [`{a|${this.data.name}}`, `{b|${this.data.count}}`].join('\n'),
          show: this.data.showTitle,
          left: 'center',
          top: 'center',
          textStyle: {
            rich: {
              a: {
                color: '#86B3E9',
                fontSize: 12,
                lineHeight: 30,
              },
              b: {
                color: '#fff',
                fontSize: 22,
                lineHeight: 20,
              },
            },
          },
          triggerEvent: true,
        },
        grid: {
          top: '0px',
          left: '0px',
          right: '0',
          bottom: '0px',
        },
        series: [
          {
            type: 'pie',
            radius: ['68%', '90%'],
            x: 'center',
            label: {
              show: false,
            },
            top: '5%',
            left: '5%',
            right: '5%',
            bottom: '5%',
            labelLine: {
              show: false,
            },
            color:
              this.el === 'chart2'
                ? ['#1986D9', '#293752']
                : [
                    '#3a689f',
                    `${_this.$g.treeDefinate ? '#ff6d55' : '#c4b9b9'}`,
                    '#ee8987',
                    '#ffcc66',
                    '#ff0066',
                    '#660066',
                    '#006666',
                    '#00ff99',
                  ],
            data: dataArr /* [
              {
                value: 400,
                name: '正常',
                itemStyle: {
                  color: '#3a689f',
                },
              },
              {
                value: 300,
                name: '异常',
                itemStyle: {
                  color: '#ee8987',
                },
              },
              {
                value: 200,
                name: '离线',
                itemStyle: {
                  color: '#c4b9b9',
                },
              },
            ], */,
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      myChart.on('click', function(params) {
        if (params.name === '故障') {
          _this.$emit('showExceptionHandle');
        }
      });
    },
  },
};
</script>

<style scoped>
.char-bar {
  width: 160px;
  height: 160px;
}
</style>
