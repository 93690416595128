<template>
  <div>
    <div class="cameral-group p-sm flex items-center justify-between">
      <div class="flex-one flex items-center text-lightblue over-hidden">
        <template>
          <i
            class="icon-cameral m-r-xs"
            v-if="getUserInfo.loginName === data.createUser"
          ></i>
          <i class="icon-cameral-my m-r-xs" v-else> </i>
        </template>
        <span class="bage m-r-xs bage-dark">{{ data.deviceCount }}</span>
        <span class="ellipsis wd">{{ data.groupName }}</span>
      </div>
      <div class="flex items-center text-lightblue">
        <a-icon
          type="edit"
          title="修改"
          class="m-r-sm"
          @click="editGroupHandle(data)"
        />
        <a-icon type="rest" title="删除" @click="delHandle(data)" />
        <i
          class="icon-g icon-auth pointer m-r-xs"
          @click="handleAuth()"
          v-if="false"
        ></i>
        <i
          class="icon-g icon-loop pointer"
          @click="handleTurn()"
          v-if="false"
        ></i>
      </div>
    </div>
    <!--  <group-auth-dialog :visible="authVisible" @closeDialog="closeAuth" />
    <turn-dialog :visible="turnVisible" @closeDialog="closeTurn" /> -->
    <!--  <new-group-dialog
      :visible="newGroupVisble"
      :edit="true"
      :resource="true"
      :isChild="true"
      @closeDialog="newGroupVisble = false"
    /> -->
  </div>
</template>

<script>
import GroupAuthDialog from '@/components/GroupAuthDialog';
import TurnDialog from '@/components/TurnDialog';
// import NewGroupDialog from '@/components/NewGroupDialog';
import { mapMutations, mapActions, mapState } from 'vuex';
export default {
  name: 'GroupCameralItem',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      authVisible: false,
      turnVisible: false,
      newGroupVisble: false,
    };
  },
  computed: {
    ...mapState({
      getUserInfo: state => state.login.userInfo,
    }),
  },
  watch: {},
  components: {
    // eslint-disable-next-line vue/no-unused-components
    GroupAuthDialog,
    // eslint-disable-next-line vue/no-unused-components
    TurnDialog,
    // NewGroupDialog,
  },
  methods: {
    ...mapMutations([
      'setCurrentCameraGroup',
      'setGroupId',
      'setSelectCameraList',
    ]),
    ...mapActions([
      'getCameraGroupCondition',
      'deleteCameraGroup',
      'getCameraListCheck',
    ]),
    handleAuth() {
      this.authVisible = true;
    },
    handleTurn() {
      this.turnVisible = true;
    },
    closeAuth() {
      this.authVisible = false;
    },
    closeTurn() {
      this.turnVisible = false;
    },
    editGroupHandle(data) {
      this.$emit('editControl', data);
    },
    delHandle(data) {
      this.$emit('delSingleHandle', data);
    },
    _getCameraGroupCondition() {
      let obj = {
        currPage: 0,
        pageSize: 0,
      };

      this.getCameraGroupCondition(obj);
    },
  },
};
</script>

<style scoped>
.icon-cameral {
  background: url('../../../assets/resource/icon-group.png') no-repeat;
  width: 22px;
  height: 17px;
  background-size: 100% 100%;
}
.cameral-group {
  background: linear-gradient(
    to left,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );
}
.wd {
  width: 60%;
}
.icon-g {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-edit {
  background-image: url('../../../assets/resource/icon-edit.png');
}
.icon-del {
  background-image: url('../../../assets/resource/icon-del.png');
}
.icon-auth {
  background-image: url('../../../assets/resource/icon-auth.png');
}
.icon-loop {
  background-image: url('../../../assets/resource/icon-loop.png');
}
</style>
