<template>
  <div class="full-width full-height">
    <div class="ht-style-t">
      <div class="full-height">
        <title-com
          text="联网状况"
          :showScreen="true"
          @goView="viewNet"
          class="m-b-sm"
        ></title-com>
        <left-menu
          :list="menuList"
          class="m-b-md"
          @showCom="showComHandle"
        ></left-menu>
        <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht">
          <top-static
            :list="networkStatistic"
            :column="curColumn"
            @sortList="handleSortDataList"
          ></top-static>
        </vue-scroll>
      </div>
    </div>

    <div class="ht-style-b">
      <div class="full-height">
        <title-com
          text="截图"
          :showScreen="true"
          class="m-b-sm"
          @goView="viewScreen"
        ></title-com>

        <div
          class="on-con relative ht-style-b-video"
          :class="{ 'p-md': !$g.isDepart, 'p-md-depart': $g.isDepart }"
        >
          <span class="absolute cor t-lf"></span>
          <span class="absolute cor t-rf"></span>
          <span class="absolute cor b-lf"></span>
          <span class="absolute cor b-rf"></span>
          <vue-scroll :ops="$root.scrollOpsY" style="height:100%">
            <div class="flex items-center justify-between">
              <screen-and-video
                class="flex-one m-r-sm btn"
                :mode="0"
                :data="screenShotCount"
                @click.native="viewScreen({ tab: 'Screen' })"
              />
              <screen-and-video
                class="flex-one btn"
                :mode="1"
                :data="screenShotVideo"
                @click.native="viewScreen({ tab: 'Video' })"
              />
            </div>

            <div class="text-center text-lightblue m-t-sm" v-show="$g.isDepart">
              <i class="icon-statis ve-m in-block m-r-sm"></i>
              <span class="m-r-md">已使用: 85%</span>
              <span>剩余空间: 40T</span>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import LeftMenu from '@/components/LeftMenu';
import TopStatic from './TopStatic';
import ScreenAndVideo from './ScreenAndVideo';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'TopLeft',
  data() {
    return {
      menuList: [
        {
          id: 0,
          name: '接入率',
        },
        {
          id: 1,
          name: '在线率',
        },
        {
          id: 3,
          name: '正常率',
        },
        /* {
          id: 4,
          name: '故障率',
        }, */
        {
          id: 4,
          name: '可控率',
        },
        /*  {
          id: 4,
          name: '共享统计',
        }, */
      ],
      currentMenu: 0,
      curColumn: [],
      column0: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },

        {
          key: 'accessedCount',
          name: '已接入量',
          sort: false,
        },
        {
          key: 'totalAccessCount',
          name: '应接入量',
          sort: false,
        },
        {
          key: 'accessedRate',
          name: '接入率',
          sort: true,
        },
      ],
      column1: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'onlineCount',
          name: '在线数',
          sort: false,
        },

        {
          key: 'onlineRate',
          name: '在线率',
          sort: true,
        },
      ],
      column3: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'normalCount',
          name: '正常数',
          sort: false,
        },

        {
          key: 'normalRate',
          name: '正常率',
          sort: true,
        },
      ],
      column4: [
        {
          key: 'roadCode',
          name: '编号',
          sort: false,
        },
        {
          key: 'roadName',
          name: '路线',
          sort: false,
        },
        {
          key: 'abnormalCount',
          name: '故障数',
          sort: false,
        },

        {
          key: 'abnormalRate',
          name: '故障率',
          sort: true,
        },
      ],
      columnDepart0: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },

        {
          key: 'accessedCount',
          name: '已接入量',
          sort: false,
        },
        {
          key: 'totalAccessCount',
          name: '应接入量',
          sort: false,
        },
        {
          key: 'accessedRate',
          name: '接入率',
          sort: false,
        },
      ],
      columnDepart1: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'onlineCount',
          name: '在线数',
          sort: false,
        },

        {
          key: 'onlineRate',
          name: '在线率',
          sort: false,
        },
      ],
      columnDepart3: [
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'normalCount',
          name: '正常数',
          sort: false,
        },

        {
          key: 'normalRate',
          name: '正常率',
          sort: false,
        },
      ],
      columnDepart4: [
        /* {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'abnormalCount',
          name: '故障数',
          sort: false,
        },

        {
          key: 'abnormalRate',
          name: '故障率',
          sort: false,
        }, */
        {
          key: 'index',
          name: '编号',
          sort: false,
        },
        {
          key: 'regionName',
          name: '省份',
          sort: false,
        },
        {
          key: 'controlCount',
          name: '可控数量',
          sort: false,
        },

        {
          key: 'controlRate',
          name: '可控率',
          sort: false,
        },
      ],
      // dataList: [],
      sortType: 1, //0默认取消
    };
  },
  components: {
    TitleCom,
    LeftMenu,
    TopStatic,
    ScreenAndVideo,
  },
  computed: {
    ...mapState({
      networkStatistic: (state) => state.resource.networkStatistic,
      screenShotData: (state) => state.resource.screenShotData,
    }),
    screenShotCount() {
      return this.screenShotData?.['截图张数'] || 0;
    },
    screenShotCamera() {
      return this.screenShotData?.['截图摄像机'] || 0;
    },
    screenShotVideo() {
      return this.screenShotData?.['videoNum'] || 0;
    },
  },
  watch: {},
  created() {
    if (this.$g.isDepart) {
      this.menuList = [
        {
          id: 0,
          name: '接入率',
        },
        {
          id: 1,
          name: '在线率',
        },
        {
          id: 3,
          name: '正常率',
        },
        {
          id: 4,
          name: '可控率',
        },
      ];
      this.currentMenu = 0;
    } else {
      this.menuList = [
        {
          id: 1,
          name: '在线率',
        },
        {
          id: 3,
          name: '正常率',
        },
      ];
      this.currentMenu = 1;
    }
  },
  mounted() {
    // this.curColumn = this[`column${this.currentMenu}`];
    if (this.$g.isDepart) {
      this.curColumn = this[`columnDepart${this.currentMenu}`];
    } else {
      this.curColumn = this[`column${this.currentMenu}`];
    }
    this._getDataList();
    this.getScreenShotCount();
  },
  methods: {
    ...mapActions(['getNetworkStatistics', 'getScreenShotCount']),
    _getDataList() {
      let data = this.$g.isDepart
        ? {}
        : {
            currPage: 0,
            pageSize: 0,
            sortField: this.currentMenu,
            sortType: this.sortType,
          };
      this.getNetworkStatistics(data);
    },
    handleSortDataList(flag) {
      this.sortType = flag ? 1 : 0;
      this._getDataList();
    },
    viewNet() {
      if (this.$g.isDepart) {
        this.$router.push({ path: '/resource/departnet-condition' });
      } else if (this.$g.regionCode == 530000) {
        this.$router.push({ path: '/resource/yn-condition' });
      } else {
        this.$router.push({ path: '/resource/net-condition' });
      }
    },
    viewScreen(params = {}) {
      this.$router.push({ name: 'ScreenAndVideo', params });
    },
    showComHandle(item) {
      this.currentMenu = item.id;
      if (this.$g.isDepart) {
        this.curColumn = this[`columnDepart${this.currentMenu}`];
      } else {
        this.curColumn = this[`column${this.currentMenu}`];
      }

      this.sortType = 1;
      this._getDataList();
    },
  },
};
</script>

<style scoped>
.ht-style-t {
  height: calc(100% - 200px);
  margin-bottom: 10px;
}
.ht-style-b {
  height: 190px;
}
.scroll-ht {
  height: calc(100% - 86px) !important;
}
.ht-style-b-video {
  height: calc(100% - 40px) !important;
}
.p-md-depart {
  padding: 20px 20px 5px;
}
</style>
