<template>
  <div class="full-width full-height p-sm">
    <div class="hd">
      <title-com text="" :showScreen="false" @goView="viewCameralStatis()" />
    </div>
    <vue-scroll :ops="$root.scrollOpsY" class="ht">
      <div class="full-width full-height items-center  flex justify-around">
        <div class="flex-one text-center">
          <span class="block text-lightblue text-center"
            >摄像机运行状况统计</span
          >
          <div class="full-width flex justify-center full-h">
            <chart-bar-common
              el="chart1"
              :data="runStatis"
              @showExceptionHandle="viewExceptHandle()"
            />
          </div>
        </div>
        <div class="flex-one text-center">
          <span class="block text-lightblue text-center">摄像机接入率统计</span>
          <div class="full-width flex justify-center full-h">
            <chart-bar-common el="chart2" :data="cameralRate" />
          </div>
        </div>
        <div class="flex-one text-center">
          <span class="block text-lightblue text-center">摄像机类型统计</span>
          <div class="full-width flex justify-center full-h">
            <chart-bar-common el="chart3" :data="cameralType" />
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import ChartBarCommon from '@/components/ChartBarCommon';
import { mapState } from 'vuex';
export default {
  name: 'BotRight',
  data() {
    return {
      /* runStatis: {
        name: '总数',
        count: '11,8505',
        showTitle: true,
      }, */
      /* cameralRate: {
        name: '已接入',
        count: '80%',
        showTitle: true,
      }, */
      /* cameralType: {
        name: '',
        count: '',
        showTitle: false,
      }, */
      totalAccessCountSum: 0,
      totalAccessedCount: 0,
    };
  },
  components: {
    TitleCom,
    ChartBarCommon,
  },
  computed: {
    ...mapState({
      classifyInfo: (state) => state.resource.classifyInfo,
      accessInfo: (state) => state.resource.accessInfo,
      networkStatistic: (state) => state.resource.networkStatistic,
    }),
    cameralType() {
      return {
        name: '',
        count: '',
        showTitle: false,
        data: this.classifyInfo ? this.classifyInfo : [],
      };
    },
    runStatis() {
      let total = 0,
        offline = 0,
        online = 0,
        error = 0;
      if (this.accessInfo) {
        // console.log('heihei',this.accessInfo);
        // 1.previousValue => 初始值或上一次回调函数叠加的值
        // 2. currentValue => 本次回调（循环）将要执行的值
        // index =>“currentValue”的索引值
        // 4. arr => 数组本身；
        total = this.accessInfo.reduce((prev, cur) => {
          return cur.total + prev;
        }, 0);
        offline = this.accessInfo.reduce((prev, cur) => {
          return cur.offline + prev;
        }, 0);
        error = this.accessInfo.reduce((prev, cur) => {
          return cur.inerror + prev;
        }, 0);
        online = this.accessInfo.reduce((prev, cur) => {
          return cur.online + prev;
        }, 0);
      }
      let arr = [
        {
          name: '在线',
          value: online,
        },
        {
          name: '离线',
          value: offline,
        },
        {
          name: '异常',
          value: error,
        },
      ];
      return {
        name: '总数',
        count: total,
        showTitle: true,
        data: arr,
      };
    },
    cameralRate() {
      let totalAccessCountSum;
      let totalAccessedCount;
      if (this.$g.isDepart) {
        totalAccessCountSum = this.networkStatistic.reduce((total, cur) => {
          return cur.totalAccessCount + total;
        }, 0);
        totalAccessedCount = this.networkStatistic.reduce((total, cur) => {
          return cur.accessedCount + total;
        }, 0);
      } else {
        totalAccessCountSum = this.totalAccessCountSum;
        totalAccessedCount = this.totalAccessedCount;
      }

      let percent =
        totalAccessCountSum == 0
          ? '0.00%'
          : parseFloat(
              (totalAccessedCount / totalAccessCountSum) * 100
            ).toFixed(2) + '%';
      let arr = [
        {
          name: '已接入',
          total: totalAccessedCount,
        },
        {
          name: '未接入',
          total:
            totalAccessCountSum - totalAccessedCount > 0
              ? totalAccessCountSum - totalAccessedCount
              : 0,
        },
      ];
      return {
        name: '已接入',
        count: percent,
        showTitle: true,
        data: arr,
      };
    },
  },
  watch: {},
  created() {
    if(this.$g.regionCode == 530000){
      this.$api.resource.getAccessNumber().then((res) => {
      if (res.code === 200) {
        this.totalAccessCountSum = res.data.estimateQuantity;
        this.totalAccessedCount = res.data.realQuantity;
      }
    });
    }
    
  },
  methods: {
    viewCameralStatis() {
      this.$router.push({ path: '/resource/cameral-statistic' });
    },
    viewExceptHandle() {
      this.$router.push({ path: '/resource/cameral-exception' });
    },
  },
};
</script>

<style scoped>
.full-h {
  /* height: calc(100% - 20px); */
  height: 100%;
}
.ht {
  height: 100% !important;
}
.ht >>> .__view {
  display: flex;
  align-items: center;
}
</style>
