<template>
  <div class="flex items-center justify-between">
    <span :class="['relative p-w-sm text-white', { tit: !!text }]">{{
      text
    }}</span>
    <span class="full-screen" v-if="showScreen" @click="handleClick"
      ><i class="icon-full pointer"></i
    ></span>
  </div>
</template>

<script>
export default {
  name: 'TitleCom',
  props: {
    text: {
      type: String,
      default: '',
    },
    showScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleClick() {
      this.$emit('goView');
    },
  },
};
</script>

<style scoped>
.tit::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 19px;
  background: #96b9e4;
}
</style>
