<template>
  <div class="group-container">
    <a-modal
      :title="getTitle"
      :visible="visible"
      :maskClosable="false"
      :centered="true"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      width="25%"
      @cancel="handleCancel"
    >
      <vue-scroll :ops="$root.scrollOpsY" style="height:100%">
        <div class="m-w-sm">
          <div class="m-b-sm">
            <span class="text-white block m-b-xs"
              ><span class="red">*</span>组名称:</span
            >
            <a-input placeholder="请填写" v-model="cameraGroupName" />
          </div>
          <div class="m-b-sm">
            <span class="text-white block m-b-xs">描述内容:</span>
            <div class="relative">
              <a-textarea
                placeholder="请输入描述内容"
                :rows="4"
                v-model="cameraGroupInfo"
                :maxLength="100"
              />
              <span class="absolute b0 r0"
                ><span class="red">{{
                  cameraGroupInfo ? cameraGroupInfo.length : 0
                }}</span
                >/100</span
              >
            </div>
          </div>
          <div class="m-b-sm">
            <span class="text-white block m-b-xs">选择摄像机:</span>
            <div>
              <span class="text-lightblue flex items-center m-b-sm"
                ><!-- <i class="r-add m-r-sm" @click="addHandle"></i> --><a-icon
                  type="plus-circle"
                  class="m-r-sm"
                  @click="addHandle"
                />已选
                <span class="text-line m-l-xs m-r-xs">{{
                  selectCameraList ? selectCameraList.length : 0
                }}</span>
                路摄像机</span
              >
              <div
                class="camera-container scroll"
                v-show="selectCameraList.length"
              >
                <ul class="p-xs">
                  <li
                    class="flex items-center justify-between"
                    v-for="(item, index) in selectCameraList"
                    :key="index"
                  >
                    <span class="ellipsis flex-one">{{ item.cameraName }}</span>
                    <span
                      class="text-red pointer weight m-l-md"
                      @click="delSingle(item.cameraId)"
                      >×</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="m-b-sm">
            <span class="text-white block m-b-xs"
              ><span class="red">*</span>选择角色:</span
            >
            <a-select
              mode="multiple"
              class="full-width"
              placeholder="请选择角色"
              v-model="currentRole"
            >
              <a-select-option v-for="item in roleList" :key="item.roleCode">
                {{ item.roleName }}
              </a-select-option>
            </a-select>
          </div>
          <div class="m-b-sm">
            <span class="text-white block m-b-xs"
              ><span class="red">*</span>选择用户:</span
            >
            <a-select
              mode="multiple"
              class="full-width"
              placeholder="请选择用户"
              v-model="currentUser"
            >
              <a-select-option v-for="item in userList" :key="item.userId">
                {{ item.loginName }}
              </a-select-option>
            </a-select>
          </div>
          <div class="m-b-sm">
            <span class="text-white block m-b-xs">是否启用:</span>
            <a-radio-group name="radioGroup" v-model="cameraGroupStatus">
              <a-radio :value="1">
                启用
              </a-radio>
              <a-radio :value="0">
                停用
              </a-radio>
            </a-radio-group>
          </div>
        </div>
      </vue-scroll>
    </a-modal>
    <cameral-select
      :visible="cameraSelectVisible"
      :resource="resource"
      @closeCameralDialog="closeCameralDialogHandle"
    />
  </div>
</template>

<script>
import CameralSelect from '@/components/CameralSelect';
import { CODE_OK } from '@/request/config_code';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'NewGroupDialog',
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    edit: {
      type: Boolean,
    },
    resource: {
      type: Boolean,
      default: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmLoading: false,
      cameraGroupName: '',
      cameraGroupInfo: '',
      cameraGroupStatus: 1,
      cameraSelectVisible: false,
      roleList: [],
      userList: [],
      currentRole: [],
      currentUser: [],
    };
  },
  watch: {
    visible() {
      this._getRoleList();
      this._getUserList();
      this.cameraGroupInfo = this.currentCameraGroup
        ? this.currentCameraGroup.remark
        : '';
      this.cameraGroupName = this.currentCameraGroup
        ? this.currentCameraGroup.groupName
        : '';
      this.cameraGroupStatus = this.currentCameraGroup
        ? this.currentCameraGroup.status
        : 1;
      this.currentUser = this.currentCameraGroup
        ? this.currentCameraGroup.userIds
        : [];
      /*  this.setSelectCameraList(
        this.currentCameraGroup
          ? this.resource
            ? new Array(this.currentCameraGroup.cameraInfoNumber)
            : this.currentCameraGroup.children
          : []
      ); */
      this.setSelectCameraList(
        this.currentCameraGroup ? this.currentCameraGroup.children : []
      );
    },
    edit(nv) {
      if (nv) {
        this._getSelectedRole();
      }
    },
  },
  computed: {
    ...mapState({
      selectCameraList: (state) => state.collect.selectCameraList,
      currentCameraGroup: (state) => state.collect.currentCameraGroup,
    }),
    getTitle() {
      return `${this.edit ? '编辑' : '新增'}摄像机组`;
    },
  },
  components: {
    CameralSelect,
  },
  created() {
    this._getRoleList();
    this._getUserList();
  },
  methods: {
    ...mapMutations([
      'setSelectCameraList',
      'setCurrentCameraGroup',
      'setCurrentComIndex',
      'setGroupId',
    ]),
    ...mapActions([
      'newCameraGroup',
      'getCameraGroup',
      'modifyCameraGroup',
      'getCameraGroupCondition',
      'getCameraListCheck',
    ]),

    _getSelectedRole() {
      this.$api.cameraGroup
        .getSelectedRole({ groupId: this.currentCameraGroup.groupId })
        .then((res) => {
          if (res.code === CODE_OK) {
            this.currentRole = res.data.map((item) => item.roleCode + '');
          }
        });
    },
    _getRoleList() {
      this.$api.cameraGroup.getRoleList().then((res) => {
        if (res.code === CODE_OK) {
          this.roleList = res.data;
        }
      });
    },
    _getUserList() {
      this.$api.cameraGroup.getUserList().then((res) => {
        if (res.code === CODE_OK) {
          this.userList = res.data;
        }
      });
    },
    delSingle(cameraId) {
      let newSelectCamera = this.selectCameraList.filter(
        (item) => item.cameraId != cameraId
      );
      this.setSelectCameraList(newSelectCamera);
    },
    /* handleRoleChange(value) {
      console.log(value);
    },
    handleUserChange(value) {
      console.log(value);
    }, */
    handleOk() {
      this.confirmLoading = true;
      /* console.error('fsadf', this.cameraGroupName); */
      if (this.cameraGroupName == '') {
        this.$message.error('组名称不能为空');
        this.confirmLoading = false;
        return;
      }
      if (this.currentRole && this.currentRole.length <= 0) {
        this.$message.error('角色不能为空');
        this.confirmLoading = false;
        return;
      }
      if (this.currentUser && this.currentUser.length <= 0) {
        this.$message.error('用户不能为空');
        this.confirmLoading = false;
        return;
      }
      let obj = {};
      let params = {
        remark: this.cameraGroupInfo,
        groupName: this.cameraGroupName,
        enable: this.cameraGroupStatus,
        cameraIds: this.selectCameraList.map((item) => item.cameraId),
        roleCodes: this.currentRole,
        userIds: this.currentUser,
      };
      if (this.edit) {
        params.groupId =
          this.currentCameraGroup && this.currentCameraGroup.groupId;
        let objParams = {
          data: params,
          instructionsDel: {
            module: '摄像机组管理',
            page: '',
            feature: '修改摄像机组',
            description: `修改摄像机组: 修改前：${this.currentCameraGroup.groupName},修改后：${params.groupName}`,
          },
        };
        this.$api.cameraGroup.updateGroups(objParams).then((data) => {
          this.confirmLoading = false;
          if (data.code === CODE_OK) {
            this.$message.success('编辑摄像机组成功');
            if (this.resource) {
              if (this.isChild) {
                this.$emit('refreshData');
                this.resetData();
              }
            }
          } else {
            this.$message.error(data.message);
          }
        });
        /*  this.modifyCameraGroup(obj).then((data) => {
          this.confirmLoading = false;
          if (data.code === 200) {
            this.$message.success('修改摄像机组成功');
            if (this.resource) {
              if (this.isChild) {
                this.$emit('refreshData');
              } else {
                this._getCameraGroupCondition();
              }
            } else {
              this.getCameraGroup();
            }
          }
          this.resetData();
        }); */
      } else {
        obj = params;
        let objParams = {
          data: obj,
          instructionsDel: {
            module: '摄像机组管理',
            page: '',
            feature: '新增摄像机组',
            description: `新增摄像机组: ${obj.groupName}`,
          },
        };
        this.$api.cameraGroup.addGroups(objParams).then((data) => {
          this.confirmLoading = false;
          if (data.code === CODE_OK) {
            this.$message.success('新增摄像机组成功');
            if (this.resource) {
              if (this.isChild) {
                this.$emit('refreshData');
                this.resetData();
              }
            }
          } else {
            this.$message.error(data.message);
          }
        });
        /* this.newCameraGroup(obj).then((data) => {
          this.confirmLoading = false;
          if (data.code === 200) {
            this.$message.success('新增组成功');
            if (this.resource) {
              if (this.isChild) {
                this.$emit('refreshData');
              } else {
                this._getCameraGroupCondition();
              }
            } else {
              this.getCameraGroup();
            }
          }
          this.resetData();
        }); fix以前*/
      }
    },
    _getCameraGroupCondition() {
      let obj = {
        currPage: 0,
        pageSize: 0,
      };

      this.getCameraGroupCondition(obj);
    },
    resetData() {
      this.cameraGroupInfo = '';
      this.cameraGroupName = '';
      this.cameraGroupStatus = 1;
      this.currentRole = undefined;
      this.currentUser = undefined;
      this.setSelectCameraList([]);
      this.setCurrentCameraGroup(null);
      this.$emit('closeDialog');
    },
    handleCancel() {
      this.resetData();
    },
    addHandle() {
      this.$emit('addDialog');
      this.cameraSelectVisible = true;
      //设置默认数据
      this.setCurrentComIndex(0);
      //设置GroupID，查已选摄像机
      this.setGroupId(
        this.currentCameraGroup ? this.currentCameraGroup.id : ''
      );
      if (this.edit) {
        /* this.getCameraListCheck({
          currPage: 0,
          pageSize: 0,
          cameraGroupId: this.currentCameraGroup.id,
        }).then((res) => {
          if (res.code === 200) {
            this.setSelectCameraList(res.data);
          }
        }); fixsxy修改*/
      }
    },
    closeCameralDialogHandle() {
      this.cameraSelectVisible = false;
    },
  },
};
</script>

<style scoped>
.camera-container {
  border: 1px solid #86b3e9;
  max-height: 60px;
  height: auto;
  overflow: auto;
}
</style>
