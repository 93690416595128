<template>
  <div class="screen-video flex flex-column justify-center justify-center">
    <div class="flex items-center">
      <div class="icon-info text-lightblue m-r-sm">
        <i :class="['block', showData.iconClass]"></i>
      </div>
      <div class="text-info flex-one">
        <span class="text-white block f24 line1">{{ data }}</span>
        <span class="text-lightblue f12" v-if="false">60%</span>
      </div>
    </div>
    <span class="lh12 block  text-lightblue">{{ showData.text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ScreenAndVideo',
  props: {
    mode: {
      type: Number,
    },
    data: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    showData() {
      if (this.mode == 0) {
        return {
          iconClass: 'icon-screen',
          text: '截图',
        };
      } else {
        switch (this.$g.regionCode) {
          case 530000:
            return {
              iconClass: 'icon-camera',
              text: '摄像机数量',
            };
          default:
            return {
              iconClass: 'icon-video',
              text: '录像',
            };
        }
      }
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.icon(@pic) {
  background: url('~@assets/resource/@{pic}.png') 0 0 / contain no-repeat;
  height: 28px;
  width: 28px;
}
.screen-video {
  .icon-info {
    .icon-camera {
      .icon('icon_camera');
    }
  }
}
</style>
<style scoped>
.screen-video {
  height: 80px;
  background: linear-gradient(
    to left,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );
  padding: 0 16px;
}
.icon-screen {
  background: url('../../../assets/resource/icon-screenshot.png') no-repeat;
  width: 28px;
  height: 28px;
  background-size: 100% 100%;
}
.icon-video {
  background: url('../../../assets/resource/icon-video.png') no-repeat;
  width: 28px;
  height: 28px;
  background-size: 100% 100%;
}
.lh12 {
  margin-top: 2px;
}
.line1 {
  line-height: 1.2;
}
</style>
