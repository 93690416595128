<template>
  <div class="full-width full-height p-sm relative" ref="wrap">
    <!-- 数据看板 -->
    <title-com text="数据看板" class="m-b-sm"></title-com>
    <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht-t">
      <div>
        <!-- <template>
            <auth-mode v-if="!hasPermission(10921112)"></auth-mode> -->
           <!--  <template v-else> -->
              <div class="on-con relative p-sm">
            <span class="cor absolute t-lf"></span>
            <span class="cor absolute t-rf"></span>
            <span class="cor absolute b-lf"></span>
            <span class="cor absolute b-rf"></span>
            
                <div class="con-main flex flex-column justify-center">
                  <div class="text-right text-lightblue m-b-xs f12">
                    更新时间：{{ updateTime }}
                  </div>
                  <div class="flex-one f-bg m-r-sm p-md text-center">
                    <span class="f12 text-lightblue block">摄像机接入数</span>
                    <span class="text-white f24">{{ total | formatNum }}</span>
                    <div class="m-t-xs flex f12 bd-s-t p-t-sm">
                      <span class="text-normal  flex-one text-center"
                        ><span class="block">{{ online | formatNum }}</span
                        >在线数</span
                      >
                      <span
                        class="text-unnormal flex-one text-center pointer"
                        v-if="!$g.isHideException"
                        @click="goException"
                        ><span class="block">{{ error | formatNum }}</span
                        >故障数</span
                      >
                      <span
                        :class="[
                          'flex-one text-center',
                          `${$g.treeDefinate ? 'text-yunOffline' : 'text-offline'}`,
                        ]"
                        ><span class="block">{{ offline | formatNum }}</span
                        >离线数</span
                      >
                    </div>
                  </div>
                  <!-- <div class="flex-one f-bg">
                  <span class="f12 text-lightblue block">分享路数</span>
                  <span class="text-white f24">1,713</span>
                  <div class="m-t-xs f12 text-lightblue">
                    <span class="block m-b-xs pointer" @click="goBorrow()"
                      >调阅申请: <span class="bage">41</span></span
                    >
                    <span class="block pointer" @click="goPending()"
                      >待处理申请: <span class="bage bage-red">5</span>
                    </span>
                  </div>
                </div> -->
                </div>
            
              </div>
          <!-- </template> -->
        <!-- </template>-->
      </div> 
    </vue-scroll>

    <!-- 摄像机组 -->
    <div class="cameragroup-title-wrapper">
      <title-com
        text="摄像机组"
        :showScreen="true"
        class="m-b-sm"
        @goView="viewGroup"
      ></title-com>
      <div class="r-count">
        <span class="text-lightblue m-r-xs ve-m">({{ dataList.length }})</span>
        <i
          class="r-add btn ve-m"
          title="新增摄像机组"
          @click="openGroupHandle()"
        />
      </div>
    </div>
    <div ref="scrollDom" class="scroll-ht-b relative">
      <div class="absolute spin" v-if="!dataList.length">
        <a-spin/>
      </div>
      <div
        class="scroll"
        ref="scrollDom2"
        v-if="dataList.length"
        style="height:100%;overflow:auto;"
      >
        <!-- <vuescroll-carousel
        :type="type"
        :intervalTime="intervalTime"
        ref="vCarousel"
      > -->
        <group-cameral-item
          v-for="(item, index) in dataList"
          :key="index"
          :data="item"
          class="group-camera"
          @editControl="editControlHandle"
          @delSingleHandle="handleDelSingle"
        ></group-cameral-item>
      </div>
    </div>

    <!-- <group-add-edit :visible="groupAddVisible" @closeDialog="closeGroup" /> -->
    <new-group-dialog
      :visible="groupAddVisible"
      :edit="isEdit"
      :resource="true"
      :isChild="true"
      @refreshData="refreshDataHandle"
      @closeDialog="closeDialogHandle"
    />
    <!-- <div class="text-white">{{ accessFirstInfo }}</div> -->
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import GroupCameralItem from './GroupCameralItem';
// import GroupAddEdit from './GroupAddEdit';
import NewGroupDialog from '@/components/NewGroupDialog';
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { CODE_OK } from '@/request/config_code';
// import AuthMode from '@/components/AuthMode';
// import vuescrollCarousel from '@/components/module/vuescrollCarousel/carousel';
export default {
  name: 'TopRight',
  data() {
    return {
      groupAddVisible: false,
      type: 'vertical',
      intervalTime: 3000,
      ht: {},
      isEdit: false,
      paginationOpt: {
        current: 1,
        defaultPageSize: 10,
        // defaultPageSize: 2,
      },
      dataList: [],
      totalData: 0,
    };
  },
  components: {
    TitleCom,
    // eslint-disable-next-line vue/no-unused-components
    GroupCameralItem,
    // GroupAddEdit,
    // eslint-disable-next-line vue/no-unused-components
    NewGroupDialog,
    // AuthMode
    // vuescrollCarousel,
  },
  computed: {
    ...mapState({
      accessFirstInfo: (state) => state.resource.accessFirstInfo,
      cameraGroupLists: (state) => state.resource.cameraGroupLists,
    }),
    updateTime() {
      return moment().format('YYYY/MM/DD HH:mm:ss');
    },
    total() {
      if (!this.accessFirstInfo) {
        return 0;
      }
      return this.accessFirstInfo.reduce((prev, cur) => {
        return cur.total + prev;
      }, 0);
    },
    offline() {
      if (!this.accessFirstInfo) {
        return 0;
      }
      return this.accessFirstInfo.reduce((prev, cur) => {
        return cur.offline + prev;
      }, 0);
    },
    online() {
      if (!this.accessFirstInfo) {
        return 0;
      }
      return this.accessFirstInfo.reduce((prev, cur) => {
        return cur.online + prev;
      }, 0);
    },
    error() {
      if (!this.accessFirstInfo) {
        return 0;
      }
      return this.accessFirstInfo.reduce((prev, cur) => {
        return cur.inerror + prev;
      }, 0);
    },
  },
  watch: {},
  mounted() {
    this._getCameraGroupLists();
    this.setHt();
  },
  methods: {
    ...mapMutations([
      'setCameraGroupLists',
      'setCurrentCameraGroup',
      'setGroupId',
    ]),
    ...mapActions(['getCameraGroupCondition']),
    loadData() {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.scrollDom2;
        // console.log('this.scrollDom', this.$refs.scrollDom2);
        if (this.scrollDom) {
          this.parentH =
            this.scrollDom.parentNode && this.scrollDom.parentNode.offsetHeight;

          this.scrollDom.addEventListener('scroll', () => {
            // debugger;
            this.scrollHandle();
          });
        }
      });
    },
    scrollHandle() {
      let pageH = this.scrollDom.scrollHeight;
      let scrollT = this.scrollDom.scrollTop; //滚动条top
      let aa = (pageH - this.parentH - scrollT) / this.parentH;
      /*  let aa = pageH - this.parentH - scrollT;
      console.log(
        aa,
        this.paginationOpt.current,
        this.paginationOpt.defaultPageSize
      ); */
      if (
        aa < 0.02 &&
        this.paginationOpt.current * this.paginationOpt.defaultPageSize <
          this.totalData
      ) {
        // console.log(this.parentH, pageH, scrollT);
        this.paginationOpt.current++;
        this._getCameraGroupLists();
      }
    },
    setHt() {
      const wrapHt = this.$refs.wrap.clientHeight;
      const scrollDomHt = wrapHt - this.$refs.scrollDom.offsetTop - 10;
      this.ht = {
        height: `${scrollDomHt}px`,
      };
    },
    mouseEnterHandle() {
      this.$refs.vCarousel.stopAutoPlay();
    },
    mouseLeaveHandle() {
      this.$refs.vCarousel.setAutoPlay();
    },
    _getCameraGroupLists() {
      let obj = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        groupName: this.cameraGroupName,
        createUser: this.cameraUser,
      };
      this.$api.cameraGroup.getGroupList(obj).then((res) => {
        // console.error(res);
        if (res.code === CODE_OK) {
          this.dataList = this.dataList.concat(res.data);
          this.totalData = res.total;
          if (this.dataList.length > 0) {
            this.loadData();
          }
        }
      });
    },

    /*  _getCameraGroups() {
      let obj = {
        currPage: 0,
        pageSize: 0,
      };
      this.setCameraGroupLists([]);
      this.getCameraGroupCondition(obj);
    }, */
    goBorrow() {
      this.$router.push({ path: '/resource/apply', query: { mode: 1 } });
    },
    goPending() {
      this.$router.push({
        path: '/resource/apply',
        query: { mode: 2 },
      });
    },
    closeGroup() {
      this.groupAddVisible = false;
    },
    openGroupHandle() {
      if (!this.hasPermission(1092111310)) {
        this.permissionWarning();
        return;
      }

      this.groupAddVisible = true;
      this.isEdit = false;
    },
    viewGroup() {
      this.$router.push({ path: '/resource/cameral-group' });
    },
    editControlHandle(params) {
      if (!this.hasPermission(1092111311)) {
        this.permissionWarning();
        return;
      }

      this.$api.cameraGroup
        .getSelectedCamera({
          groupId: params.groupId,
        })
        .then((res) => {
          if (res.code === 200) {
            let newObj = JSON.parse(JSON.stringify(params));
            newObj.children = res.data;
            this.isEdit = true;
            this.groupAddVisible = true;
            this.setCurrentCameraGroup(newObj);
            this.setGroupId(newObj.id);
          }
        });
    },
    closeDialogHandle() {
      this.groupAddVisible = false;
      this.isEdit = false;
    },
    handleDelSingle(records) {
      if (!this.hasPermission(1092111314)) {
        this.permissionWarning();
        return;
      }

      const params = {
        groupIds: [records.groupId],
      };
      let description = `删除摄像机组:${records.groupName}`;
      let data = {
        data:params,
        instructionsDel: {
          module: '摄像机组管理',
          page: '',
          feature: `${params.length > 1 ? '批量' : ''}删除摄像机组`,
          description,
        },
      }
      this.$confirm({
        title: '提示',
        content: '你确定删除该条摄像组分组吗?',
        onOk: () => {
          this.$api.cameraGroup.batchDel(data).then((data) => {
            if (data.code === CODE_OK) {
              this.$message.success('删除成功');
              this.refreshDataHandle();
            }
          });
        },
        onCancel: () => {},
      });
    },
    refreshDataHandle() {
      this.paginationOpt.current = 1;
      this.dataList = [];
      this._getCameraGroupLists();
    },
    goException() {
      
      if (this.error <= 0) {
        // this.$message.error('暂无故障摄像机！');
        return;
      }
      this.$router.push('/resource/exception-manage');
    },
  },
  /* destroyed() {
    this.scrollDom.removeEventListener('scroll', () => {
      this.scrollHandle();
    });
  }, */
};
</script>

<style lang="less" scoped>
.cameragroup-title-wrapper {
  @titleComHeight: 26px;

  position: relative;

  .r-count {
    left: 3.6rem;
    line-height: @titleComHeight;
    position: absolute;
    top: 0;
  }
}
</style>
<style scoped>
.f-bg {
  background: linear-gradient(
    to left,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );
  /* height: 164px; */
  /* padding: 20px 0 0 20px; */
}
.group-camera {
  margin-bottom: 10px;
}
.group-camera:last-child {
  margin-bottom: 0;
}
.scroll-ht-t {
  height: 10rem !important;
  margin-bottom: 0.5rem;
}
.scroll-ht-b {
  height: calc(100% - 14rem) !important;
}
.spin{
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
}
</style>
