<template>
  <div class="full-width full-height p-sm">
    <div class="hd flex items-center justify-between">
      <div class="flex text-lightblue items-center">
        <title-com text="摄像机接入" :showScreen="false" />
        <a-icon
          type="rollback"
          class="m-l-sm pointer"
          v-if="backFlag"
          @click="backHandle()"
        />
      </div>
      <i class="icon-all pointer" @click="viewAllHandle()"></i>
    </div>
    <stack-chart :data="accessInfo" />
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import StackChart from '@/components/StackChart';
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
  name: 'BotLeft',
  data() {
    return {};
  },
  components: {
    TitleCom,
    StackChart,
  },
  computed: {
    ...mapState({
      accessInfo: (state) => state.resource.accessInfo,
      backFlag: (state) => state.resource.backFlag,
      backPath: (state) => state.resource.backPath,
    }),
    getAccessInfoArr() {
      return this.accessInfo ? this.accessInfo : [];
    },
  },
  watch: {},
  mounted() {
    if (this.$g.isDepart) {
      this.getRegion();
    } else {
      this.getAccessInfo({ organizationId: '' });
    }
  },
  methods: {
    ...mapMutations(['setBackPath', 'setIsClick']),
    ...mapActions(['getAccessInfo', 'getRegion']),
    viewAllHandle() {
      this.$router.push({ path: '/resource/cameral-join' });
    },
    backHandle() {
      this.backPath.pop();
      let organizationId =
        this.backPath.length > 0 ? this.backPath[this.backPath.length - 1] : '';
      if (this.$g.isDepart) {
        if (String(organizationId) !== '') {
          if (!organizationId) {
            organizationId = '';
          }
          this.getAccessInfo({ organizationId });
        } else {
          this.getRegion();
        }
        this.setIsClick(true);
      } else {
        this.getAccessInfo({ organizationId });
      }

      this.setBackPath(this.backPath);
    },
  },
};
</script>

<style scoped></style>
