<template>
  <div class="full-width full-height relative">
    <traffic-amap
      ref="amapIns"
      :baseTopAdcodes="regionCode"
      :baseTopAdcodesName="regionName"
      :data="cameraList"
      @mapChange="mapChangeHandle"
      v-if="!$qiFang"
    ></traffic-amap>
    <qf-traffic-amap v-if="$qiFang" ref="amapIns" />
    <div class="absolute l20 b20" v-show="false">
      <div class="flex">
        <traffic-status
          className="online"
          @selectItem="selectItemHandle"
          :onlineStatus="classifyStatus"
          value="1"
        />
        <traffic-status
          className="offline"
          @selectItem="selectItemHandle"
          :onlineStatus="classifyStatus"
          value="0"
        />
        <traffic-status
          className="error"
          @selectItem="selectItemHandle"
          :onlineStatus="classifyStatus"
          value="2"
          v-if="!$g.isHideException"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TrafficAmap from '@/components/TrafficAmap3D';
import QfTrafficAmap from '@/components/QFTrafficAmap3D';
import { mapActions } from 'vuex';
import { mercatorTolonlat } from '@/tools';
import { wgs84_to_gcj02 } from '@/tools/wgs84_to_gcj02.js';
import TrafficStatus from '@/components/TrafficStatus';
export default {
  name: 'TopMain',
  components: {
    TrafficAmap,
    QfTrafficAmap,
    TrafficStatus,
  },
  data() {
    return {
      regionName: window.g.title,
      regionCode: window.g.regionCode,
      cameraList: [],
      classifyStatus: ['0', '1', '2'],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    setTimeout(() => {
      this._getPagePot();
    }, 20);
  },
  methods: {
    ...mapActions(['getPagePot']),
    /*  _getPagePot() {
      let mapInstance = this.$refs.amapIns;
      if (mapInstance) {
        let mymap = mapInstance.mapInstance,
          bounds = mymap.getBounds(),
          zoom = mymap.getZoom(),
          postBody = {
            cityCode: '',
            classify: '',
            orgnizationId: '',
            provinceCode: '',
            road: '',
          };
        let params = {
          params: {
            zoomLevel: zoom, //13,
            northEast: bounds.Rd[1].toString(),
            southWest: bounds.Rd[3].toString() 
          data: postBody,
        };
        this.getCameraList(params);
      }
    }, */
    /* 3d
            northEast: bounds.northeast.toString(),
            southWest: bounds.southwest.toString(), */
    _getPagePot() {
      let mapInstance = this.$refs.amapIns;
      if (mapInstance) {
        let mymap = mapInstance.mapInstance,
          // bounds = mymap.getBounds(),
          // zoom = mymap.getZoom(),
          zoom = '',
          bounds = {},
          northEast = '',
          southWest = '',
          postBody = {
            cityCode: '',
            classify: '',
            orgnizationId: '',
            provinceCode: '',
            road: '',
          };
        if (this.$qiFang) {
          let view = mymap.getView();
          // let center = view.getProjection();
          /*     let ext = center.getExtent(); */
          let mapSize = view.calculateExtent(mymap.getSize());
          let firstNode = mercatorTolonlat({ x: mapSize[0], y: mapSize[1] });
          var firstNodeLonLat = wgs84_to_gcj02(firstNode.x, firstNode.y);

          let secondNode = mercatorTolonlat({ x: mapSize[2], y: mapSize[3] });
          let secondNodeLonLat = wgs84_to_gcj02(secondNode.x, secondNode.y);
          zoom = view.getZoom();
          northEast = secondNodeLonLat.join(',');
          southWest = firstNodeLonLat.join(',');
        } else {
          bounds = mymap.getBounds();
          zoom = mymap.getZoom();
          northEast = bounds.Td[1].toString();
          southWest = bounds.Td[3].toString(); //3d
          // northEast = bounds.northeast.toString();
          // southWest = bounds.southwest.toString(); //非3d
        }
        let params = {
          params: {
            zoomLevel: zoom, //13,
            northEast,
            southWest /* 3d
            northEast: bounds.northeast.toString(),
            southWest: bounds.southwest.toString(), */,
          },
          data: postBody,
        };
        this.getCameraList(params);
      }
    },
    getCameraList(params) {
      this.getPagePot(params).then((res) => {
        this.copyCamera = res.data;
        this.cameraList = this.filterCamera(res.data);
      });
    },
    mapChangeHandle() {
      this._getPagePot();
    },
    selectItemHandle(status) {
      // console.log(status);//["0","1"] 0 offline   1  online   2erro
      this.classifyStatus = status;
      let copyCamera = this.copyCamera.slice();
      // console.log(copyCamera);
      this.cameraList = this.filterCamera(copyCamera);
      // console.error('haha', this.cameraList);
    },
    filterCamera(cameraList) {
      let cameralist = cameraList.filter((item) => {
        if (this.classifyStatus.includes(item.onlineStatus)) {
          return true;
        }
      });
      // console.error('haah', this.classifyStatus, cameralist);
      return cameralist;
    },
  },
  beforeDestroy() {
    this.$refs.amapIns && this.$refs.amapIns.destroyMap();
  },
};
</script>

<style scoped>
.l20 {
  left: 20px;
}
.b20 {
  bottom: 20px;
}
</style>
