<template>
  <div
    class="full-width full-height relative"
    @mouseenter="mouseEnterHandle"
    @mouseleave="mouseLeaveHandle"
  >
    <div
      class="absolute arrow-chart arrow-chart2 "
      v-show="showTip"
      @click="clickHandle('prev')"
    >
      <span class="full-height flex items-center justify-center"
        ><a-icon type="left"
      /></span>
    </div>
    <div class="full-width full-height" id="chartStack"></div>
    <div
      class="absolute arrow-chart "
      v-show="showTip"
      @click="clickHandle('next')"
    >
      <span class="full-height flex items-center justify-center"
        ><a-icon type="right"
      /></span>
    </div>
  </div>
</template>

<script>
import echarts from '@/tools/echarts';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'StackChart',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showTip: false,
      start: 0,
      end: 0,
      page: 0,
    };
  },
  computed: {
    ...mapState({
      backPath: (state) => state.resource.backPath,
      isClick: (state) => state.resource.isClick,
    }),
  },
  watch: {
    data: {
      handler() {
        this.$nextTick(() => {
          this.renderChartLine();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.renderChartLine();
    });
  },
  methods: {
    ...mapActions(['getAccessInfo', 'getDepartAccessInfo']),
    ...mapMutations(['setBackPath', 'setIsClick']),
    mouseEnterHandle() {
      if (this.data.length > 20) {
        this.showTip = true;
      }
    },
    mouseLeaveHandle() {
      this.showTip = false;
    },
    clickHandle(mode) {
      let pageSize = Math.ceil(this.data.length / 20);
      if (mode === 'next') {
        this.page = this.page < pageSize - 1 ? ++this.page : pageSize - 1;
      } else {
        this.page = this.page > 0 ? --this.page : 0;
      }
      // console.log('haha', this.page);
      this.start = this.page * 20;
      this.end = this.start + 20;
      // console.log(this.page, pageSize, this.data.length, this.start, this.end);
      this.chart.dispatchAction({
        type: 'dataZoom',
        // 开始位置的数值
        startValue: this.start,
        // 结束位置的数值
        endValue: this.end,
      });
    },
    renderChartLine() {
      let vm = this;
      let obj = document.getElementById('chartStack'); //"abnormal-chart"
      // console.error('haha-------------', this.data);
      let lengendArr = this.data.map((item) => {
        return item.name.length > 5
          ? item.name.substring(0, 4) + '...'
          : item.name;
      });
      let offlineData = this.data.map((item) => item.offline);
      let onlineData = this.data.map((item) => item.online);
      let errorData = this.data.map((item) => item.inerror);
      let getSeriesArr = [
        {
          name: '在线',
          type: 'bar',
          stack: '总量',
          itemStyle: {
            color: '#3A689F',
          },
          barWidth: '15px',
          data: onlineData,
        },

        {
          name: '离线',
          type: 'bar',
          stack: '总量',
          itemStyle: {
            color: `${vm.$g.treeDefinate ? '#ff6d55' : '#C4B9B9'}`,
          },
          barWidth: '15px',
          data: offlineData,
        },
        {
          name: '故障',
          type: 'bar',
          stack: '总量',
          itemStyle: {
            color: '#EE8987',
          },
          barWidth: '15px',
          data: errorData,
        },
      ];
      if (this.$g.isHideException) {
        getSeriesArr.pop();
      }
      if (!obj) {
        return false;
      }
      //console.log("pieChartData=>", pieChartData, pieChartDataTotal);
      let myChart = echarts.init(obj);
      this.chart = myChart;
      //var that = this;
      // 绘制图表
      myChart.setOption(
        {
          tooltip: {
            show: true,
            trigger: 'item',
          },
          legend: {
            show: true,
            // orient: 'vertical',
            left: 'center',
            itemWidth: 6,
            itemHeight: 6,
            textStyle: {
              color: 'rgba(134, 179, 233, 1)',
            },
          },
          grid: {
            top: '15%',
            left: '5%',
            right: '5%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLine: {
              lineStyle: {
                color: 'rgba(134, 179, 233, 1)',
              },
            },
            axisLabel: {
              rotate: -30,
            },
            axisTick: {
              lineStyle: {
                color: 'rgba(134, 179, 233, 0.1)',
              },
            },
            data: lengendArr,
          },
          yAxis: {
            type: 'value',
            splitNumber: 3,
            splitLine: {
              show: true,
              lineStyle: {
                color: ['rgba(134, 179, 233, 0.1)'],
                width: 1,
                type: 'solid',
              },
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(134, 179, 233, 0.1)',
              },
            },
            axisTick: {
              lineStyle: {
                color: 'rgba(134, 179, 233, 0.1)',
              },
            },
            axisLabel: {
              color: 'rgba(134, 179, 233, 1)',
            },
          },
          dataZoom: {
            type: 'inside',
            endValue: 20,
            startValue: 0,
            xAxisIndex: 0,
            zoomLock: true,
          },
          series: getSeriesArr,
        },
        true
      );
      window.addEventListener('resize', () => {
        myChart.resize();
      });

      myChart.off('click');
      myChart.on('click', (params) => {
        //let sersName = params.name;
        // console.error('click', params);
        let type = params.componentType;
        if (type === 'series') {
          // console.error(params.dataIndex);
          /*  let vo = this.baseDataList[params.dataIndex];
          this.barChartSelectPath.push(vo.id);

          this.getAllData(vo.id); */

          let vo = this.data[params.dataIndex];
          if (this.$g.isDepart) {
            // console.log(1);
            if (!this.isClick) {
              return;
            }
            this.setIsClick(false);
            let params = {
              dimensionType: 'organization',
              params: {
                regionCode: vo.regionCode,
              },
            };
            this.getDepartAccessInfo(params).then(() => {
              this.setBackPath(['']);
            });
          } else {
            this.getAccessInfo({
              regionCode: vo.regionCode,
              organizationId: vo.id,
            }).then((data) => {
              if (data.length > 0) {
                let backPath = Array.from(new Set([...this.backPath, vo.id]));
                // console.error('haha-----------', backPath);
                this.setBackPath(backPath);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.arrow-chart {
  right: 0;
  top: 50%;
  background: #3a689f;
  width: 3%;
  color: #fff;
  font-size: 20px;
  z-index: 2;
  height: 60px;
  line-height: 60px;
  transform: translateY(-50%);
}
.arrow-chart2 {
  left: 0;
  right: auto;
}
</style>
