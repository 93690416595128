<template>
  <span
    :class="[
      `sp-item p-w-sm m-r-sm pointer ${className}`,
      { current: onlineStatus.includes(value) },
    ]"
    @click="selectHandle(value)"
    ><i class="m-r-xs"></i>
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'TrafficStatus',
  props: {
    className: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    onlineStatus: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      /* onlineStatus: ['0', '1', '2'], */
    };
  },
  computed: {},
  watch: {},
  methods: {
    selectHandle(value) {
      if (this.onlineStatus.includes(value)) {
        let index = this.onlineStatus.indexOf(value);
        this.onlineStatus.splice(index, 1);
      } else {
        this.onlineStatus.push(value);
      }
      // this.setOnlineStatus(this.onlineStatus);
      this.$emit('selectItem', this.onlineStatus);
    },
  },
};
</script>

<style scoped>
.sp-item {
  height: 30px;
  line-height: 30px;
  background: #0b1429;
  border: 1px solid #7a7a7a;
  border-radius: 6px;
  width: 76px;
  /* width: 60px; */
  white-space: nowrap;
  color: #fff;
  text-align: center;
}
.sp-item.error {
  border-color: #e08e8d;
  background: rgba(11, 20, 41, 1);
}
.sp-item.online {
  border-color: rgb(132, 170, 202);
  background: rgba(11, 20, 41, 1);
}
.sp-item.offline {
  border-color: #b5b2b2;
  background: rgba(11, 20, 41, 1);
}
.sp-item.online.current {
  background: rgba(120, 177, 249, 1);
  border-color: rgba(120, 177, 249, 1);
  color: #0e1e3f;
  font-weight: bold;
}
.sp-item.error.current {
  background: rgba(255, 111, 87, 1);
  border-color: rgba(255, 111, 87, 1);
  color: #5b2f2a;
  font-weight: bold;
}
.sp-item.offline.current {
  background: rgba(161, 161, 161, 1);
  border-color: rgba(161, 161, 161, 1);
  color: #4c4140;
  font-weight: bold;
}
.sp-item i {
  background: url('../assets/home/cameral-default.png') no-repeat;
  width: 14px;
  height: 14px;
  display: inline-block;
  background-size: 100% 100%;
  vertical-align: -1px;
}
.sp-item.online.current i {
  background: url('../assets/home/cameral-current.png') no-repeat;
  background-size: 100% 100%;
}
.sp-item.error.current i {
  background: url('../assets/home/c-error.png') no-repeat;
  background-size: 100% 100%;
}
.sp-item.offline.current i {
  background: url('../assets/home/c-offline.png') no-repeat;
  background-size: 100% 100%;
}
</style>
