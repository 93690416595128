<template>
  <div class="bg-f full-width full-height flex items-center">
    <div class="bot-left full-height">
      <bot-left />
    </div>
    <div class="bot-right full-height m-l-md">
      <bot-right />
    </div>
  </div>
</template>

<script>
import BotLeft from './BotLeft';
import BotRight from './BotRight';
export default {
  name: 'BottomComponent',
  data() {
    return {};
  },
  components: {
    BotLeft,
    BotRight,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.bot-left {
  width: 60%;
}
.bot-right {
  width: 40%;
}
</style>
