<template>
  <div class="top-static">
    <div class="flex items-center justify-around">
      <span
        :class="[
          'flex-one bg-title  text-center',
          { wd1: index === 0, 'm-r-xxs': index !== column.length - 1 },
        ]"
        v-for="(item, index) in column"
        :key="item.key"
        >{{ item.name
        }}<i
          class="icon-sort pointer m-l-xs"
          @click="handleSort()"
          v-if="item.sort"
        ></i
      ></span>
    </div>
    <div class="text-lightblue f-body">
      <div
        class="flex items-center justify-around "
        v-for="(item, index) in list"
        :key="index"
      >
        <!--  {{ item[column[index].key] }} -->
        <span
          v-for="itemc in column"
          :key="itemc.key"
          class="flex-one text-center ellipsis"
        >
          {{ item[itemc.key] }}
        </span>
      </div>
    </div>
    <!-- <div
      class="flex items-center justify-around text-lightblue f-body"
      v-for="(item, index) in list"
      :key="index"
    >
      <span class="flex-one m-r-xxs text-center">{{
        item[column[index].key]
      }}</span>
       <span class="flex-one m-r-xxs text-center ellipsis">{{
        item.regionName
      }}</span>
      <span class="flex-one m-r-xxs text-center">{{ item.accessedCount }}</span>
      <span class="flex-one m-r-xxs text-center">{{
        item.totalAccessCount
      }}</span>
      <span class="flex-one text-center">{{ item.accessedRate }}</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'TopStatic',
  props: {
    column: {
      type: Array,
      default() {
        return [];
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      flag: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleSort() {
      this.flag = !this.flag;
      this.$emit('sortList', this.flag);
    },
  },
};
</script>

<style scoped>
.wd1 {
  width: 40px;
}
.bg-title {
  background: rgba(150, 185, 228, 0.1);
  color: #86b3e9;
  line-height: 16px;
}
.f-body {
  line-height: 36px;
}
</style>
