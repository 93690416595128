<template>
  <div>
    <a-modal
      title="分组授权"
      width="30%"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template>
        <a-form :form="form">
          <a-form-item label="请选择要授权的账号">
            <a-select
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              @change="handleChange"
              v-decorator="[
                'account',
                { rules: [{ required: true, message: '请选择账号！' }] },
              ]"
            >
              <a-select-option value="1">
                Option 1
              </a-select-option>
              <a-select-option value="2">
                Option 2
              </a-select-option>
              <a-select-option value="3">
                Option 3
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="有效期">
            <a-range-picker
              @change="onChange"
              class="full-width"
              v-decorator="[
                'dateRange',
                { rules: [{ required: true, message: '请选择有效期！' }] },
              ]"
            />
          </a-form-item>
        </a-form>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'GroupAuthDialog',
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      confirmLoading: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          setTimeout(() => {
            this.visible = false;
            this.confirmLoading = false;
          }, 2000);
        }
      });
      this.$emit('closeDialog');
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.$emit('closeDialog');
    },
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
  },
};
</script>

<style scoped></style>
